<template>
  <div>
    <h4 class="pl-2 font-weight-bold">チーム管理</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  @input="fromDateSelected"
                  locale="ja"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="toDate"
                  locale="ja"
                  @input="toDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
              <b-button
                class="primary mb-4 mb-md-3 ml-2"
                block
                @click="infoModal()"
                >新規作成 <i class="fa fa-plus" aria-hidden="true"></i
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(name)="row">
        {{ row.item.name }}
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="infoModal(row.item)"
          ><b-badge variant="primary" class="py-2">更新</b-badge
          ><esports-loading-button
            v-if="row.item.teamId === teamInfo.teamId && action_type === 'edit'"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block"
          @click="deleteModal(row.item)"
          ><b-badge variant="danger" class="py-2">削除</b-badge></span
        >
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row)"
          ><b-badge variant="info" class="text-white py-2"
            >{{ row.detailsShowing ? "詳細非表示" : "詳細表示" }}</b-badge
          ><esports-loading-button
            v-if="row.item.teamId === teamInfo.teamId && action_type === 'info'"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
      </template>

      <template #row-details="row">
        <b-card no-body class="overflow-hidden animate__animated" :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']">
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <b-card-img-lazy
                v-if="row.item.content"
                :src="row.item.content.picture"
                alt="Image"
                class="rounded-0"
                v-bind="mainProps('#fff')"
              ></b-card-img-lazy>
              <label class="mt-1 ml-1" v-if="row.item.content">{{ row.item.content.labelFileName }}</label>
            </b-col>
            <b-col md="7" lg="7">
              <b-card-body>
                <h5>{{ row.item.title }}</h5>
                <b-card-text>
                  <b>概要: </b>
                  {{ row.item.description }}
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Team ID</b>: {{ row.item.teamId }}</li> -->
                <li v-if="row.item.content">
                  <b>選手名</b>:
                  <b-badge
                    v-for="player in row.item.content.players"
                    :key="player.playerId"
                    variant="warning"
                    class="py-1 mx-1"
                    >{{ player.name }}</b-badge
                  >
                </li>
                <li><b>作成日</b>: {{ row.item.createdDate }}</li>
                <template v-if="row.item.content">
                  <li>
                    <b>実績</b>:
                    <b-badge
                      class="mx-1"
                      v-for="(archive, index) in row.item.content.achievement"
                      :key="index"
                      >{{ archive }}</b-badge
                    >
                  </li>
                  <li v-if="row.item.content.extensionInfos">
                    <b>追加項目</b>:
                    <template>
                      <p
                        class="mb-0"
                        v-for="(info, index) in Object.entries(
                          row.item.content.extensionInfos
                        )"
                        :key="index"
                      >
                        <b>{{ info[0] }}</b
                        >: {{ info[1] }}
                      </p>
                    </template>
                  </li>
                </template>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging 
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult">
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 ml-3"
        ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="teamInfo.id"
      :title="teamInfo.title_modal"
      @hide="resetteamInfo"
      @shown="handleUpdateTeam"
      ref="team-info-modal"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <b-form-input
            v-model="teamInfo.title"
            type="text"
            placeholder="チーム名"
            :state="ckStateGame('title')"
          ></b-form-input>
          <b-form-invalid-feedback>チーム名は必須です。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-form-textarea
            placeholder="概要"
            v-model="teamInfo.description"
            rows="3"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback
            >概要は必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2 filter-search mx-0">
          <label>選手名</label>
          <!-- <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            class="dropdown-search"
            :class="{ 'is-invalid': $v.teamInfo.playerIds.$error }"
          > -->
          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            class="dropdown-search"
          >
            <template #button-content>
              <b-form-tag
                @remove="removeTag(tag)"
                v-for="tag in selected_name"
                :key="tag.value"
                variant="info"
                >{{ tag.text }}</b-form-tag
              >
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group class="mb-0" label-size="sm">
                <b-form-input
                  v-model="search_name_player"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <li class="dropdown-events">
              <ul class="list-group list-unstyled">
                <b-dropdown-item-button
                  v-for="(option, index) in availableOptions"
                  :key="index"
                  @click="onOptionClick({ option })"
                  class="text-truncate"
                >
                  <span
                    :title="option.text"
                    class="text-truncate d-block selected-name"
                    >{{ option.text }}</span
                  >
                </b-dropdown-item-button>
              </ul>
            </li>
            <b-dropdown-text v-if="availableOptions.length === 0">
              該当選手がありません。
            </b-dropdown-text>
          </b-dropdown>
          <b-form-invalid-feedback>選手名を選択してください。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2">
          <label>実績</label>
          <b-form-tags
            placeholder="エンターキーで追加"
            v-model="teamInfo.achievement"
          ></b-form-tags>
          <b-form-tags
            v-model="teamInfo.achievement"
            v-if="teamInfo.achievementExt && teamInfo.achievementExt.length"
            placeholder=""
            disabled
            class="mt-2"
          ></b-form-tags>
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >追加項目
            <span
              role="button"
              @click="addAttribute"
              v-if="teamInfo.extensionInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group 
            enter-active-class="animate__animated animate__slideInUp" 
            leave-active-class="animate__animated animate__fadeOutDown"
          >
          <div
            class="d-flex align-items-center position-relative"
            v-for="(item, index) in teamInfo.extensionInfos"
            :key="`key-${index}`"
          >
            <b-row>
              <b-col>
                <b-form-group class="mb-2">
                  <b-form-input
                    type="text"
                    placeholder="項目名"
                    v-model="item.key"
                    :state="ckStateExtentInfo(index, 'key')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >項目名は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2">
                  <b-form-input
                    type="text"
                    placeholder="値"
                    v-model="item.value"
                    :state="ckStateExtentInfo(index, 'value')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >値は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <div
              class="pl-2 btn-remove-attribute"
              @click="removeAttribute(index)"
            >
              <i
                class="fa fa-times text-danger"
                aria-hidden="true"
                role="button"
              ></i>
            </div>
          </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="position-relative">
          <b-form-file
            v-model="teamInfo.file"
            placeholder=""
            :state="teamId ? '' : ckStateGame('file')"
            drop-placeholder=""
            class="hide-banner"
            accept="image/*"
          ></b-form-file>
          <label class="custom-file-label filename">{{ filename }}</label>
          <b-form-invalid-feedback>画像アップロードは必須です。</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="onSubmitTeam(close)"
          >{{ teamId ? '更新' : '登録' }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- delete Game Modal -->
    <b-modal
      id="deleteEvent"
      hide-header
      header-class="text-danger"
      centered
    >
      <div class="form-data">
        <h5 class="text-center py-3">このチームを完全に削除してもよろしいでしょうか?</h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleDeleteEvent(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

export default {
  name: "TeamList",
  data() {
    return {
      isLoading: false,
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      teamId: "",
      items: [],
      fields: [
        {
          key: "title",
          label: "チーム名",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "description",
          label: "概要",
          sortable: true,
          class: 'w-50'
        },
        {
          key: "createdDate",
          label: "作成日",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        title: "",
        description: "",
        createdDate: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["title", "description", "createdDate"],
      teamInfo: {
        id: "info-modal",
        file: null,
        title_modal: "",
        title: "",
        description: "",
        extensionInfos: [],
        playerIds: [],
        achievement: [],
      },
      teamTemp: null,
      MAX_ATTRIBUTES: 5,
      msgErrors: [],
      msgSuccess: null,
      search_name_player: "",
      options_name: [],
      selected_name: [],
      action_type: "",
      isEnableCloseModal: false,
      filename: "",
      limit: 0,
      isDetailRow: false
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.teamId) {
      return {
        teamInfo: {
          title: { required },
          // playerIds: { required },
          extensionInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
        },
      };
    }
    return {
      teamInfo: {
        title: { required },
        file: { required },
        // playerIds: { required },
        extensionInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
    "teamInfo.file": {
      deep: true,
      immediate: true,
      handler(nVal) {
        if (nVal) {
          this.filename = nVal.name;
        } else {
          this.filename = "画像をアップロード";
        }
      },
    },
  },
  computed: {
    isValidGame() {
      return !this.$v.teamInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    criteria() {
      return this.search_name_player.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options_name;

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });
      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([this.getPlayerList(), this.getTeamsAll()]);
    this.hideBeforeLoading = true;

    this.teamTemp = { ...this.teamInfo };
    this.resetItemArraysTeamTemp();

    if(this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    resetItemArraysTeamTemp() {
      if (this.teamTemp.extensionInfos) delete this.teamTemp.extensionInfos;
      if (this.teamTemp.achievement) delete this.teamTemp.achievement;

      this.teamTemp.extensionInfos = cloneDeep(this.teamInfo.extensionInfos);
      this.teamTemp.achievement = cloneDeep(this.teamInfo.achievement);
    },
    handleUpdateTeam() {
      this.teamTemp = { ...this.teamInfo };
      this.resetItemArraysTeamTemp();
    },
    showModalConfirmCloseTeam() {
      this.$bvModal
        .msgBoxConfirm("本当にキャンセルしてもよろしいでしょうか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["team-info-modal"].hide();
          }
        });
    },
    async getPlayerList() {
      const result = await this.$store
        .dispatch("adminPlayer/getPlayersAll")
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        let playerList = result.filter(player => player.hidden === false);
        this.options_name = playerList.map((player) => {
          return {
            value: player.playerId,
            text: player.name,
          };
        });

        this.options_name = orderBy(this.options_name, ['text'], ['asc']);
      }
    },
    async getTeamsAll(params) {
      const result = await this.$store
        .dispatch("adminTeam/getTeamsAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((player, index) => {
          player.picture = `${process.env.VUE_APP_BACKEND + player.picture}`;

          if (player.createdDate) {
            player.createdDate = moment(player.createdDate).format(
              "YYYY-MM-DD"
            );
          }

          return player;
        });
        this.totalRows = this.items.length;
        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getTeamsAll(params);
    },
    async onSubmitTeam(close) {
      if (this.validate()) {
        let params = {
          file: this.teamInfo.file,
          teamInfo: "",
          teamId: this.teamId,
        };

        let extensionInfos = {};
        this.teamInfo.extensionInfos.forEach((item) => {
          extensionInfos[item.key] = item.value;
        });

        if(isEmpty(extensionInfos)) extensionInfos = null;

        let teamInfo = {
          title: this.teamInfo.title,
          description: this.teamInfo.description,
          achievement: this.teamInfo.achievement,
          playerIds: this.teamInfo.playerIds,
          extensionInfos,
        };

        if (this.teamId) {
          teamInfo.teamId = this.teamId;
        }

        params.teamInfo = JSON.stringify(teamInfo);

        this.showMsgConfirmCreate(() => {
          this.isEnableCloseModal = true;
          this.upsertTeam(params, close);
        });
      }
    },
    async upsertTeam(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminTeam/upSertTeam", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListTeam(result, close);
      }
    },
    showMsgConfirmCreate(cb) {
      let msg = this.teamId
        ? "このチームを更新してもよろしいですか？"
        : "このチームを登録してもよろしいですか？";

      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["team-info-modal"].hide();
        });
    },
    async infoModal(item) {
      if (!item) {
        this.teamId = "";
        this.teamInfo.title_modal = `新規作成`;
        this.teamInfo.id = "info-modal";
        this.teamInfo.teamId = "";

        this.teamTemp.title_modal = `新規作成`;
        this.teamTemp.id = "info-modal";
        this.teamTemp.teamId = "";

        this.filename = "画像をアップロード";

        this.$root.$emit("bv::show::modal", this.teamInfo.id);
        return;
      }

      this.teamInfo.title_modal = `更新`;
      this.teamInfo.teamId = item.teamId;
      this.action_type = "edit";

      const result = await this.$store
        .dispatch("adminTeam/getDetailTeamByAdmin", item.teamId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.loadDataInfo(item, result);

        this.$root.$emit("bv::show::modal", this.teamInfo.id);
      }
    },
    async handleDeleteEvent(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminTeam/deleteTeam", this.teamId)
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.reloadListTeam(result, close);
      }
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.title = "";
      this.filters.description = "";
      this.filters.createdDate = "";

      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    async reloadListTeam(result, close) {
      this.msgSuccess = result.message;
      this.msgErrors = [];
      this.isSubmitted = false;
      this.resetData();
      this.handleSuccessResp(close);
      await this.handleFilterEvents();
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    toDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    validate() {
      this.$v.$touch();
      return this.isValidGame;
    },
    ckStateGame(val) {
      let field = this.$v.teamInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    ckStateExtentInfo(index, val) {
      let infos = this.$v.teamInfo.extensionInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    resetData() {
      this.page = 1;
      this.teamId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    deleteModal(item) {
      this.teamId = item.teamId;
      this.$root.$emit("bv::show::modal", "deleteEvent");
    },
    resetteamInfo(data) {
      if (
        !isEqual(this.teamInfo, this.teamTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmCloseTeam();
        data.preventDefault();
        return;
      }

      this.teamInfo.title_modal = "";
      this.teamInfo.file = null;
      this.teamInfo.title = "";
      this.teamInfo.description = "";
      this.teamInfo.achievement = [];
      this.teamInfo.playerIds = [];
      this.selected_name = [];
      this.teamInfo.extensionInfos = [];
      this.action_type = "";

      this.isEnableCloseModal = false;
      this.filename = "";

      this.$v.$reset();
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs['table-data']?.getTbodyTrs().length ?? 0;
      });
    },
    addAttribute() {
      if (this.teamInfo.extensionInfos.length > this.MAX_ATTRIBUTES) return;
      this.teamInfo.extensionInfos.push({ key: "", value: "" });
    },
    removeAttribute(index) {
      this.teamInfo.extensionInfos.splice(index, 1);
    },
    async handleDetail(row) {
      let currentIdx = this.items.findIndex(
        (team) => team.teamId === row.item.teamId
      );
      this.teamInfo.teamId = row.item.teamId;
      this.action_type = "info";
      this.isDetailRow = true;

      if (row.detailsShowing) {
        this.teamInfo.teamId = "";
        this.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      const result = await this.$store
        .dispatch("adminTeam/getDetailTeam", row.item.teamId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.teamInfo.teamId = "";
        let content = result;
        content.picture = `${process.env.VUE_APP_BACKEND + content.picture}`;
        content.extensionInfos = JSON.parse(content.extensionInfos);

        content.labelFileName = this.getNamePicture(result.picture);

        if (currentIdx > -1) {
          this.items[currentIdx].content = result;
        }

        row.toggleDetails();
      }
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
    onOptionClick({ option }) {
      this.search_name_player = "";
      this.selected_name.push(option);
      this.teamInfo.playerIds.push(option.value);

      this.selected_name = this.getUniqueListBy(this.selected_name, "value");
      this.teamInfo.playerIds = this.getUniqueListBy(
        this.teamInfo.playerIds,
        ""
      );
    },
    removeTag(tag) {
      let tagIndex = this.selected_name.findIndex(
        (item) => item.value === tag.value
      );
      if (tagIndex > -1) {
        this.selected_name.splice(tagIndex, 1);
        this.teamInfo.playerIds.splice(tagIndex, 1);
      }
    },
    renderTagsPlayer(tags) {
      tags.forEach((item) => {
        let tagIndex = this.options_name.findIndex((tag) => tag.value === item);
        if (tagIndex > -1) {
          this.selected_name.push({
            text: this.options_name[tagIndex].text,
            value: this.options_name[tagIndex].value,
          });
        }
      });
    },
    loadDataInfo(item, result) {
      this.teamInfo.teamId = "";
      this.teamInfo.title = result.title;
      this.teamId = item.teamId;
      this.teamInfo.description = result.description;
      this.teamInfo.achievement = result.achievement;
      this.teamInfo.achievementExt = result.achievementExt;

      this.teamInfo.playerIds = result.players?.map((item) => item.playerId);
      this.renderTagsPlayer(this.teamInfo.playerIds);

      let extendsInfo = result.extensionInfos
        ? JSON.parse(result.extensionInfos)
        : [{ key: "", value: "" }];

      if (!Array.isArray(extendsInfo)) {
        this.teamInfo.extensionInfos = [];

        Object.entries(extendsInfo).forEach((item) => {
          this.teamInfo.extensionInfos.push({
            key: item[0],
            value: item[1],
          });
        });
      }

      this.filename = this.getNamePicture(result.picture);
    },
  },
};
</script>